<ng-container *ngIf="!showInputScreen && !phoneCode">
  <div class="modal-body">
    <ui-alert
      *ngIf="showErrorAlert"
      [iconColor]="'black-icon'"
      [alertTextTemplate]="alertTextTemplate"
      [alertStatus]="'warning'"
    ></ui-alert>
    <div class="alert alert-danger" role="alert" *ngIf="mfaTechDifficultiesStatus">
      <span class="alert-icon material-icons-round"><i class="alert-icon material-icons">warning</i></span>
      <div class="text">We can't complete your request at this time. Please try again later.</div>
    </div>
    <p>Get a code sent to you at {{ maskedNumber }}.</p>
    <p class="mb-0">Message and data rates may apply.</p>
  </div>
  <div class="modal-footer justify-content-right">
    <button id="btnBack" type="button" class="btn btn-secondary" (click)="showFactorList.emit()">Back</button>
    <button id="btnSendCode" type="button" class="btn btn-primary" (click)="getCode(1)">Send Code</button>
  </div>
</ng-container>

<ng-container *ngIf="showInputScreen">
  <div class="modal-body" *ngIf="showInputScreen">
    <ui-alert
      *ngIf="showErrorAlert"
      [iconColor]="'black-icon'"
      [alertTextTemplate]="alertTextTemplate"
      [alertStatus]="'warning'"
    ></ui-alert>
    <p>Enter the code sent to your phone.</p>
    <p class="mb-2"><strong>Enter Code</strong></p>

    <input
      type="text"
      (keyup)="onInput($event)"
      [(ngModel)]="inputValue"
      maxlength="6"
      class="form-control {{ verifyErrorCount >= 1 ? 'is-invalid' : '' }}"
      id="oktacode"
    />
    <small class="form-text invalid-feedback" [ngClass]="verifyErrorCount >= 1 ? 'd-block' : 'd-none'">
      <i class="material-icons icon-sm">error_outline</i>
      <span class="align-self-center pl-1" [innerHTML]="'This is not a valid code; try again'"></span>
    </small>
  </div>
  <div class="modal-footer justify-content-right">
    <button id="btnBack" type="button" class="btn btn-link" *ngIf="showResendCodeButton" [disabled]="isLoading" (click)="getCode(1)">
      Resend Code
    </button>
    <button
      id="btnBack"
      type="button"
      class="btn btn-secondary"
      *ngIf="showBackButton"
      [disabled]="isLoading"
      (click)="backToSendCodeScreen()"
    >
      Back
    </button>
    <button id="btnContinue" type="button" class="btn btn-primary" (click)="getCode(2)" [disabled]="isLoading">Continue</button>
  </div>
</ng-container>

<!-- This ng-template is rendered inside of the alert -->
<ng-template #alertTextTemplate>
  <div class="text text-black">
    <p class="m-0">We recently sent you a code. Please wait 30 seconds before requesting another one.</p>
  </div>
</ng-template>
