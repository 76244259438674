<nav
  class="navbar navbar-expand-lg navbar-light bg-white shadow"
  id="public-header"
  *ngIf="!ondemandLinks && !onDemandheader && !portalLinks && !patientHeaderState"
>
  <div class="container-xl d-flex justify-content-between align-items-center responsive-img">
    <div>
      <button
        *ngIf="headerLinks"
        class="navbar-toggler"
        type="button"
        (click)="isMenuCollapsed = !isMenuCollapsed"
        aria-controls="navbarPublic"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="material-icons icon-lg">menu</i>
      </button>
      <a class="navbar-brand navbar-logo" id="lnkBrandingHome" routerLink="/landing" (click)="isMenuCollapsed = true"
        ><img id="logo-img" alt="Labcorp | Patient" src="assets/images/logo-patient-color.svg"
      /></a>
    </div>

    <div class="hide-desktop" *ngIf="showSignInButton">
      <a (click)="login()" class="btn btn-primary size-btn">Sign In</a>
    </div>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarPublic">
      <ul class="navbar-nav ml-auto">
        <ng-container *ngIf="headerLinks">
          <li class="nav-item desktop-nav">
            <a href="{{ onDemandUrl }}" class="nav-link" data-label="Labcorp OnDemand" (click)="isMenuCollapsed = true" target="_blank"
              ><span class="nav-link-label">Labcorp OnDemand</span></a
            >
          </li>
          <li class="nav-item">
            <a [href]="preCheckUrl" class="nav-link" data-label="Make an Appointment" (click)="isMenuCollapsed = true" target="_blank"
              ><span class="nav-link-label">Make an Appointment</span></a
            >
          </li>
          <li class="nav-item">
            <a routerLink="invoices/find" class="nav-link" data-label="Billing and Insurance" (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Billing and Insurance</span></a
            >
          </li>
          <li class="nav-item mobile-nav">
            <a href="{{ onDemandUrl }}" class="nav-link" data-label="Labcorp OnDemand" (click)="isMenuCollapsed = true" target="_blank"
              ><span class="nav-link-label">Labcorp OnDemand</span></a
            >
          </li>
          <li class="nav-item">
            <a [href]="helpCenterUrl" class="nav-link" data-label="Help Center" (click)="isMenuCollapsed = true" target="_blank"
              ><span class="nav-link-label">Help Center</span></a
            >
          </li></ng-container
        >

        <li *ngIf="isMenuCollapsed" class="nav-item">
          <a (click)="login()" class="btn btn-primary d-block mx-auto" style="width: 115px">Sign In</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<nav class="navbar bg-blue" id="public-header" *ngIf="ondemandLinks || onDemandheader">
  <div class="container-xl content-center">
    <a class="navbar-logo" id="lnkBrandingHomeOnDemand" href="{{ onDemandUrl }}" (click)="isMenuCollapsed = true"
      ><img alt="Labcorp | OnDemand" src="assets/images/OnDemandLogo.svg" class="mobile-logo"
    /></a>
  </div>
</nav>
<div class="p-1 bg-primary" *ngIf="!ondemandLinks"></div>
<header class="fixed-top">
  <patient-ui-portal-header *ngIf="portalLinks"></patient-ui-portal-header>
</header>
